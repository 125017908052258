import moment from 'moment';
import { Tag, Space } from 'antd';

export function renderMileage(mileage) {
    return Math.round(mileage / 1000);
}

export function renderDuration(duration) {
    return Math.round(duration);
}

export function renderDateTime(dateTime) {
    var mom = moment(dateTime);
    return mom.format('YY-MM-DD HH:mm');
}

export function renderLink(link, text) {
    return (
        <Space size="middle">
            <a href={link}>{text}</a>
        </Space>
    );
}

export function getCapitalLetters(nameString) {
    const fullName = nameString.split(' ');
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
}

export function renderTripType(tripType) {

    return (
        <Tag color={tripType == 0 ? 'green' : 'blue'} key={tripType}>
            {tripType == 0 ? 'Business' : 'Private'}
        </Tag>
    );
}