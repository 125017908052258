import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, Polyline, LayersControl } from 'react-leaflet'
import { Row, Col, Spin } from 'antd';
import { FetchApi } from './api';
import { ChangeView } from './mapHelper';
import TripEditor from './tripEditor';

const lineOptions = { color: 'blue' }

const TripDetails = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [trip, setItems] = useState([]);
    const [polyLine, setLine] = useState([]);
    const [center, setCenter] = useState([42.2974279, -85.628292]);       

    useEffect(() => {
        FetchApi('/api/Trip/' + props.tripKey, setError, processResult, setIsLoaded)
    }, [props.tripKey])    

    const processResult = (result) => {
        var line = [];
        result.points.forEach(element => {
            line.push(
                [element.latitude, element.longitude]
            );
        });
        setItems(result);
        setLine(line);
        setCenter([result.latStart, result.lonStart]);
    }

    const updateCompleted = (changes) => {
        console.log('completed - tripdetails')
        console.log(changes)
        props.updateCompleted(props.tripKey, changes)
    }

    const RenderMapItems = () => {
        if (trip.length == 0 || polyLine.length == 0)
            return (<Spin></Spin>);
        else
            return (
                <div>
                    <ChangeView center={center} markers={[{ latitude: trip.latStart, longitude: trip.lonStart }, { latitude: trip.latEnd, longitude: trip.lonEnd }]}></ChangeView>
                    <Polyline pathOptions={lineOptions} positions={polyLine} />
                    <Marker position={[trip.latStart, trip.lonStart]} key="1">
                        <Tooltip opacity={1} permanent>Start</Tooltip>
                    </Marker>
                    <Marker position={[trip.latEnd, trip.lonEnd]} key="2">
                        <Tooltip opacity={1} permanent>End</Tooltip>
                    </Marker>
                </div>
            );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <div className='loading'>
                <Spin tip="Loading..." ></Spin>
            </div>
        )
    } else {
        return (
            <Row>
                <Col flex="500px">
                    <TripEditor trip={trip} updateCompleted={updateCompleted}></TripEditor>
                </Col>
                <Col flex="auto">
                    <div style={{ width: "900px" }}>
                        <MapContainer center={center} zoom={10} scrollWheelZoom={true} style={{ height: "60vh", width: "auto" }}>
                            <LayersControl position="topright">
                                <LayersControl.BaseLayer checked name="Street">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name="Satellite">
                                    <TileLayer
                                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                                        maxZoom={20}
                                        subdomains={['mt1', 'mt2', 'mt3']}
                                    />
                                </LayersControl.BaseLayer>
                            </LayersControl>
                            <RenderMapItems></RenderMapItems>
                        </MapContainer>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default TripDetails;


