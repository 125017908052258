import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

export default function EvaRangePicker(props) {
    const [timeFrame, setTimeFrame] = useState({
        from: moment.utc().subtract(1, 'day').startOf('day'),
        to: moment.utc().startOf('day')
    });

    useEffect(() => {
        try {
            props.timeFrameChangedHandler(timeFrame);
        } catch (e) { }
    }, [timeFrame]);

    // Can not select days before today and today
    const disabledDate = (current) => {        
        return current > moment().endOf('day');
    }

    const onChange = (dates) => {
        setTimeFrame({
            from: moment.utc(dates[0]).startOf('day'),
            to: moment.utc(dates[1]).startOf('day')
        });
    };

    return (
        <RangePicker
            onChange={onChange}
            format={dateFormat}
            disabledDate={disabledDate}
            defaultValue={[moment(timeFrame.from, dateFormat), moment(timeFrame.to, dateFormat)]}
        />
    );
}