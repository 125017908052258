import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { FetchApi } from './api';
const { Option } = Select;

export default function DeviceChooser(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [imei, setImei] = useState(null);

    useEffect(() => {
        if (!isLoaded) FetchApi('/api/LastLocation', setError, processResult, setIsLoaded);
    }, []);

    useEffect(() => {
        try {
            props.imeiChangedHandler(imei);
        } catch (e) { }
    }, [imei]);

    const processResult = (result) => {        
        setItems(result);
        setImei(result[0].imei);
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return (
            <Select
                className='deviceDropDown'
                loading
            >
            </Select>);
    }
    else {
        return (
            <Select
                showSearch
                className='deviceDropDown'
                placeholder="Select a Device"
                optionFilterProp="children"
                onChange={setImei}                
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={imei}
            >
                {
                    items.map(device => {
                        return (<Option key={device.imei} value={device.imei}>{device.vehicleName}</Option>)
                    })
                };
            </Select>
        );        
    }
}