import React, { useState, useEffect } from 'react';
import { GlobalDtFormat } from '../components/globalconfig'
import { FetchApi } from '../components/api';

import { MapContainer, TileLayer, Marker, Popup, Tooltip, LayersControl } from 'react-leaflet'
import { Row, Col, List, PageHeader, Button, Checkbox, Spin } from 'antd';
import { ChangeView } from '../components/mapHelper';
import moment from 'moment';

export default function LiveTracking() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [showLabel, SetShowLabel] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [imei, setImei] = useState(null);
  const [center, setCenter] = useState([46.7752433, 14.3359083]);

  useEffect(() => {
    if (!isLoaded) FetchApi('/api/LastLocation', setError, setItemsRendered, setIsLoaded);
  }, [isLoaded])

  useEffect(() => {
    displayItems(items);
  }, [imei])

  const setItemsRendered = (result) => {    
    setItems(result);
    displayItems(result);
  }

  const displayItems = (result) => {
    var tmp = result;
    if (imei != null) {
      tmp = items.filter(item => item.imei == imei);
    }
    setItemsToShow(tmp);
  }

  const onChangeLabel = (e) => {
    SetShowLabel(e.target.checked);
  }

  const onChangeShowInactive = (e) => {
    setShowInactive(e.target.checked);
  }

  const GetMarker = (showLabel, item) => {
    if (showLabel == true) {
      return (
        <Marker position={[item.latitude, item.longitude]} key={item.key}>
          <Popup>
            {item.vehicleName}, {item.timeRecorded}
          </Popup>
          <Tooltip offset={[0, 25]} opacity={1} direction="bottom" permanent="True">{item.vehicleName}</Tooltip>
        </Marker>
      );
    }
    else {
      return (
        <Marker position={[item.latitude, item.longitude]} key={item.key}>
          <Popup>
            {item.vehicleName}, {item.timeRecorded}
          </Popup>
          <Tooltip>{item.vehicleName}</Tooltip>
        </Marker>
      );
    }
  }

  const Body = () => {
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className='loading'>
          <Spin tip="Loading..." ></Spin>
        </div>
      )
    } else {
      return (
        <Row>
          <Col flex="250px">
            <List className='list'
              itemLayout="horizontal"
              dataSource={items}
              header={
                <div className='listHeader'>
                  Devices <a onClick={() => { setImei(null) }} style={{ display: (imei == null ? 'none' : 'block') }}>Show All</a>
                </div>
              }
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={<a onClick={() => { setImei(item.imei) }}>{item.vehicleName}</a>}
                    description={moment(item.timeRecorded).format(GlobalDtFormat)}
                    className='listItem'
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col flex="auto">
            <MapContainer center={center} zoom={10} scrollWheelZoom={true} className="maplivelocation">
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Street">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite">
                  <TileLayer
                    url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    maxZoom={20}
                    subdomains={['mt1', 'mt2', 'mt3']}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              <ChangeView center={center} markers={itemsToShow}></ChangeView>
              {itemsToShow.map(item => GetMarker(showLabel, item))}

            </MapContainer>
          </Col>
        </Row>
      );
    }
  }

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Live Tracking"
        extra={[
          /*<Checkbox key="3" onChange={onChangeShowInactive}>Hide Inactive</Checkbox>,*/
          <Checkbox key="2" onChange={onChangeLabel}>Show Label</Checkbox>,
          <Button key="1" type="primary" onClick={() => { setIsLoaded(false) }}>Reload</Button>
        ]}
      ></PageHeader>
      <Body></Body>
    </div>
  );
}