import Userfront from "@userfront/react";

export function Host() {
    let location = window.location.href;
    let url = '';
    if (location.includes("localhost")) url = 'https://localhost:44340';
    else url = 'https://evacoreapi.azurewebsites.net'
    return url;
}

export function PutApi(endpoint, body, callBack) {
    try {

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Userfront.tokens.accessToken
            },
            body: JSON.stringify(body)
        };
        fetch(Host() + endpoint, requestOptions)
            .then(res => {
                const data = res.json();
                if (res.ok)
                {
                    callBack(true, data);
                }
                else
                {
                    const error = (data && data.message) || res.status;
                    callBack(false, error);
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                callBack(false,error);
            })
    } catch (error) {
        console.log(error);
    }
}

export function FetchApi(endpoint, setError, setResult, setIsLoaded) {
    fetch(Host() + endpoint, {
        headers: {
            Authorization: `Bearer ${Userfront.tokens.accessToken}`,
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setResult(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
}