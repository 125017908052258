import React from 'react';
import Userfront from "@userfront/react";
import {
    Link
} from 'react-router-dom';
import { Row, Col, Card } from 'antd';

export default function Dashboard() {

    return (
        <div>
            <h1>Welcome to IVA Alpha, {Userfront.user.name}</h1>
            <h3>Following functionalities are already available for testing</h3>
            <div className="site-card-wrapper" style={{ "padding-top": "25px" }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Link to="/livetracking">
                            <Card title="Live Tracking" bordered={false} hoverable>
                                <ul>
                                    <li>See Location of vehicles</li>
                                    <li>Focus on one vehicle</li>
                                </ul>
                            </Card>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <Link to="/trips">
                            <Card title="Trips" bordered={false} hoverable>
                                <ul>
                                    <li>Filter trips for vehicle and timeframe</li>
                                    <li>See tripdetails and track</li>
                                </ul>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </div>
        </div>
    );
}