import React from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Outlet,
  Navigate,
  useLocation,
  Link
} from 'react-router-dom';

import { Layout, Menu, Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Userfront from "@userfront/react";

import LiveTracking from './pages/liveTracking';
import Dashboard from './pages/dashboard';
import UserProfile from './pages/userProfile';
import Trips from './pages/trips';
import TripDetails from './pages/tripDetailsPage';
import Unauthorized from './pages/unauthorized';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

//#region Authorization

Userfront.init("pn4xj6rn");

const LoginForm = Userfront.build({
  toolId: "blalrk",
});

const SignupForm = Userfront.build({
  toolId: "rlalob"
});

const PasswordResetForm = Userfront.build({
  toolId: "krdrnm"
});

function RequireAuth({ children }) {
  
  let location = useLocation();  
  if (!Userfront.tokens.accessToken) {
    // Redirect to the /login page    
    return <Navigate to="/login" state={{ from: location }} replace />;
  } 
  else if (!Userfront.user.hasRole("admin"))
  {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return children;
}

//#endregion

const App = () => {  

  const Structure = () => {
    return (
      <Layout className="layout">
        <Header className='header'>
          <Row>
            <Col flex="auto">
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys=""                
              >
                <Menu.Item key="Dashboard"><Link to="/">Dashboard</Link></Menu.Item>
                <Menu.Item key="LiveTracking"><Link to="/livetracking">Live Tracking</Link></Menu.Item>
                <Menu.Item key="Trips"><Link to="/trips">Trips</Link></Menu.Item>                
              </Menu>
            </Col>
            <Col flex="200px">
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys=""
              >
                <SubMenu key="sub1" icon={<UserOutlined />} title={Userfront.user.name}>
                  <Menu.Item key="Profile"><Link to='/userprofile'>Profil</Link></Menu.Item>
                  <Menu.Item key="Logout" onClick={Userfront.logout}>Logout</Menu.Item>
                </SubMenu>
              </Menu>
            </Col>
          </Row>
        </Header>
        <Content className='content' style={{ height: "92vh" }}>
          <Outlet />
        </Content>
      </Layout >
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Structure />
            </RequireAuth>
          }>
          <Route index element={<Dashboard></Dashboard>} />
          <Route
            path="livetracking"
            element={<LiveTracking />}
          />
          <Route
            path="trips"
            element={<Trips />}
          />
          <Route
            path="trips/:key"
            element={<TripDetails />}
          />
          <Route
            path="userprofile"
            element={<UserProfile />}
          />
          <Route
            path="*"
            element={<Dashboard></Dashboard>}
          />
        </Route>
        <Route
            path="/unauthorized"
            element={<Unauthorized />}
          />
        <Route
          path="/login"
          element={<LoginForm></LoginForm>}
        >
        </Route>
        <Route
          path="/reset"
          element={<PasswordResetForm></PasswordResetForm>}
        >
        </Route>
        <Route
          path="/signup"
          element={<SignupForm></SignupForm>}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
