import React from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { PageHeader } from 'antd';
import TripDetails from '../components/tripDetails';


const TripDetailsPage = () => {
  const { key } = useParams();
  const navigate = useNavigate();


  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="Trip Details"
        onBack={() => navigate(-1)}
      ></PageHeader>
      <TripDetails tripKey={key}></TripDetails>
    </div>
  );
}

export default TripDetailsPage;

