import React from 'react';
import Userfront from "@userfront/react";
import { Input  } from 'antd';
const { TextArea } = Input;

export default function UserProfile() {
    const userData = JSON.stringify(Userfront.user, null, 2);

    return (
        <div>
            <h1>Userdetails</h1>
            <pre>{userData}</pre>
            <pre>Admin: {Userfront.user.hasRole("admin") ? "true" : "false"}</pre>
            <pre>Viewer: {Userfront.user.hasRole("viewer") ? "true" : "false"}</pre>            
            <h2>Access token</h2>
            <TextArea
                    value={Userfront.tokens.accessToken}
                    placeholder="Controlled autosize"
                    autoSize={{ minRows: 3, maxRows: 7 }}
                    disabled                    
                />
        </div>
    );
}