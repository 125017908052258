import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, PageHeader, Button, Spin, Modal, Space } from 'antd';
import { FetchApi } from '../components/api'
import DeviceChooser from '../components/deviceChooser'
import EvaRangePicker from '../components/rangePicker';
import { renderDateTime, renderDuration, renderMileage, renderTripType } from '../components/renderer';
import TripDetails from '../components/tripDetails';

function Trips() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [timeFrame, setTimeFrame] = useState(null);
    const [imei, setImei] = useState(null);
    const [currentTrip, setCurrentTrip] = useState(null);

    const columns = [
        /*{
            title: 'Device',
            dataIndex: 'imei',
            key: 'imei',
            render: a => { return a.slice(a.length - 5); },
        },*/
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'StartTime',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.startTime > b.startTime,
            render: a => renderDateTime(a),
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'EndTime',
            render: a => renderDateTime(a),
        },
        {
            title: 'Duration',
            dataIndex: 'durationMinutes',
            key: 'Duration',
            render: a => renderDuration(a),
        },
        {
            title: 'Start Address',
            dataIndex: 'startAddress',
            key: 'StartAddress',
        },
        {
            title: 'End Address',
            dataIndex: 'endAddress',
            key: 'EndAddress',
        },
        {
            title: 'Mil-Gps',
            dataIndex: 'mileageGps',
            key: 'mileageGps',
            render: a => renderMileage(a),
        },
        {
            title: 'Mil-Dev',
            dataIndex: 'mileageDevice',
            key: 'mileageDevice',
            render: a => renderMileage(a),
        },
        {
            title: 'Trip type',
            key: 'tripType',
            dataIndex: 'tripType',
            render: a => renderTripType(a),
        },
        {
            title: 'Action',
            key: 'key',
            dataIndex: 'key',
            render: record => {
                return (
                    <Space size="middle">
                        <a onClick={() => { setCurrentTrip(record); }}>Details</a>
                        {/*<Link to={"/trips/" + record}>Details</Link>*/}
                    </Space>
                )
            },
        },
    ];

    useEffect(() => {
        if (!isLoaded && imei != null) {
            var fromStr = timeFrame.from.format();
            var toStr = "&to=" + timeFrame.to.add(1, 'day').startOf('day').format();
            var request = '/api/Trip?from=' + fromStr + toStr;
            if (imei != null) request += ('&imei=' + imei)
            console.log(request);
            FetchApi(request, setError, setItems, setIsLoaded);
        }
    }, [isLoaded, imei, timeFrame]);

    const handleTripUpdate = (tripKey, changes) => {
        var index = items.findIndex(item=>item.key === tripKey);
        items[index].isWrong = changes.isWrong;
        items[index].comment = changes.comment;
        items[index].tripType = changes.tripType;
        setItems(items);
    }

    const Body = () => {
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div className='loading'>
                    <Spin tip="Loading..." ></Spin>
                </div>
            )
        } else {
            return (
                <div>
                    <Table columns={columns} dataSource={items} />
                    <Modal title="Trip Details"
                        visible={currentTrip != null}
                        width={1500}
                        onOk={() => {
                            setCurrentTrip(null);
                        }}
                        onCancel={() => {
                            setCurrentTrip(null);
                        }}
                        okButtonProps={{ disabled: true }}
                        cancelButtonProps={{ disabled: true }}
                    >

                        <TripDetails tripKey={currentTrip} updateCompleted={handleTripUpdate}></TripDetails>
                    </Modal>
                </div>
            )
        }
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Trips"
                extra={[
                    <DeviceChooser
                        key="1"
                        imeiChangedHandler={setImei}                        
                    >
                    </DeviceChooser>,
                    <EvaRangePicker
                        key="2"
                        timeFrameChangedHandler={setTimeFrame}
                    />,
                    <Button key="3" type="primary" onClick={() => { setIsLoaded(false) }}>Reload</Button>
                ]}
            ></PageHeader>
            <Body></Body>
        </div>
    );

}

export default Trips;