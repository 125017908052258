import React from 'react';
import Userfront from "@userfront/react";
import { Button, Result } from 'antd';

export default function Dashboard() {

    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Button type="primary" onClick={Userfront.logout}>Return to Login</Button>}
        />
    );
}