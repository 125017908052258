import React, { useState, useEffect } from 'react';
import { renderDateTime, renderDuration, renderMileage } from '../components/renderer'
import { Input, Radio, Form, Button, notification  } from 'antd';
import { PutApi } from './api';
const { TextArea } = Input;

const style = { marginBottom: "6px" };

// needed props: trip
export default function TripEditor(props) {
    const [comment, setComment] = useState("");
    const [tripType, setTripType] = useState(0);
    const [tripIsWrong, setTripIsWrong] = useState(false);
    const [form] = Form.useForm();
    const [update, setUpdate] = useState(false);
    const { trip } = props;

    useEffect(() => {
        setComment(trip.comment);
        setTripIsWrong(trip.isWrong);
        setTripType(trip.tripType);
    }, [trip]);    

    const callBack = (success, result) => {
        setUpdate(false);       

        if (success) {           
            notification.success({
                message: 'Update successfull',
                description: '',
                duration: 2,
              });
        }
        else {
            notification.error({
                message: 'Update failed',
                description: result,
                duration: 2,
              });
        }
        props.updateCompleted({
            isWrong: tripIsWrong,
            comment: comment,
            tripType: tripType
        });
    }

    function syncUpdates() {
        console.log("sync updates");
        var body = {
            isWrong: tripIsWrong,
            comment: comment,
            tripType: tripType
        }
        setUpdate(true);
        PutApi("/api/Trip/" + trip.key, body, callBack);
    }

    return (
        <Form
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 18,
            }}
            form={form}
            layout="horizontal"
            labelAlign='left'
            size='middle'            
        >
            <Form.Item label="Trip Id" style={style}>
                <Input value={trip.key} bordered={false} />
            </Form.Item>
            <Form.Item label="Device" style={style}>
                <Input value={trip.imei} bordered={false} />
            </Form.Item>
            <Form.Item label="Vehicle" style={style}>
                <Input value={trip.vehicleName} bordered={false} />
            </Form.Item>
            <Form.Item label="Start Time" style={style}>
                <Input value={renderDateTime(trip.startTime)} bordered={false} />
            </Form.Item>
            <Form.Item label="End Time" style={style}>
                <Input value={renderDateTime(trip.endTime)} bordered={false} />
            </Form.Item>
            <Form.Item label="Duration" style={style}>
                <Input value={renderDuration(trip.durationMinutes) + " minutes"} bordered={false} />
            </Form.Item>
            <Form.Item label="Distance" style={style}>
                <Input value={"GPS: " + renderMileage(trip.mileageGps) + " km, Device: " + renderMileage(trip.mileageDevice) + " km"} bordered={false} />
            </Form.Item>
            <Form.Item label="Start" style={style} >
                <TextArea value={trip.startAddress} bordered={false} autoSize={{ minRows: 1, maxRows: 6 }} />
            </Form.Item>
            <Form.Item label="End" style={style}>
                <TextArea value={trip.endAddress} bordered={false} autoSize={{ minRows: 1, maxRows: 6 }} />
            </Form.Item>
            <Form.Item label="Trip Type" style={style}>
                <Radio.Group value={tripType} onChange={(e) => { setTripType(e.target.value); }}>
                    <Radio.Button value={0}>Business</Radio.Button>
                    <Radio.Button value={1}>Private</Radio.Button>
                    <Radio.Button value={2}>Commute</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Trip Status" style={style}>
                <Radio.Group value={tripIsWrong} onChange={(e) => { setTripIsWrong(e.target.value); }}>
                    <Radio.Button value={false}>Valid</Radio.Button>
                    <Radio.Button value={true}>Wrong</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Comment" style={style}>
                <Input placeholder='Insert Comment here' allowClear onChange={(e) => { setComment(e.target.value); }} value={comment}></Input>
            </Form.Item>
            <Button onClick={syncUpdates} loading={update}>Sync Updates</Button>
        </Form>
    );
}