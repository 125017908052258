import { useMap } from 'react-leaflet'
import L from 'leaflet';
const Leaflet = L;


export function ChangeView({ center, markers }) {

    const map = useMap();
    map.setView({ lng: center[0], lat: center[1] }, 10);

    let markerBounds = Leaflet.latLngBounds([]);
    markers.forEach(marker => {
        markerBounds.extend([marker.latitude, marker.longitude])
    })

    markerBounds.isValid() && map.fitBounds(markerBounds)

    return null;
}